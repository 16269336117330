<template>
  <div>
    <div class="ovy-a">
      <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
        <el-form-item label="社会信用代码：" prop="compNo">
          <el-input
            v-model="Form.compNo"
            type="text"
            size="small"
            placeholder="请输入社会信用代码"
          />
        </el-form-item>

        <el-form-item label="机构名称：" prop="compName">
          <el-input
            v-model="Form.compName"
            type="text"
            size="small"
            placeholder="请输入机构名称"
          />
        </el-form-item>

        <el-form-item label="行政区划：" prop="areaId">
          <el-cascader
            v-model="Form.areaId"
            :options="areatreeList"
            clearable
            filterable
            :props="propsarea"
            size="small"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="联系地址：" prop="compAddress">
          <el-input
            v-model="Form.compAddress"
            type="text"
            size="small"
            placeholder="请输入联系地址"
          />
        </el-form-item>

        <el-form-item label="负责人：" prop="compUser">
          <el-input
            v-model="Form.compUser"
            type="text"
            size="small"
            placeholder="请输入负责人"
          />
        </el-form-item>

        <el-form-item label="联系方式：" prop="compPhone">
          <el-input
            v-model="Form.compPhone"
            type="text"
            size="small"
            placeholder="请输入联系方式"
          />
        </el-form-item>

        <el-form-item label="其他联系人：">
          <el-input
            v-model="Form.otherContacts"
            type="text"
            size="small"
            placeholder="请输入其他联系人"
          />
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            v-model="Form.otherContactsPhone"
            type="text"
            size="small"
            placeholder="请输入联系方式"
          />
        </el-form-item>

        <el-form-item label="到期时间：">
          <el-date-picker
            size="small"
            v-model="Form.maturityDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择到期时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="可鉴定工种：" prop="occupation">
          <div class="df flexwp">
            <div
              style="margin-right: 10px"
              v-for="(item, index) in arrOne"
              :key="index"
            >
              {{ item.name }}
              <i class="el-icon-close" @click="deleteOccupation(index, 1)"></i>
            </div>
            <el-button style="margin-left: 10px" type="text"
              ><span style="text-decoration: underline" @click="onShow"
                >添加</span
              ></el-button
            >
          </div>
        </el-form-item>

        <el-form-item label="成立日期：" prop="effectiveDate">
          <el-date-picker
            size="small"
            v-model="Form.effectiveDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择成立日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注信息：" prop="remark">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入备注信息"
            v-model="Form.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
      style="display: flex; justify-content: center"
    >
      <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
      <el-button class="bgc-bv" @click="getSure('Form')">保存</el-button>
    </span>

    <!-- 选择工种弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      width="50%"
      top="2%"
      append-to-body
    >
      <div class="flexac">
        <div>
          <div class="title">备选工种</div>
          <div class="container">
            <div class="flexac" style="margin-bottom: 10px">
              <el-input
                v-model="occupationName"
                placeholder="请输入内容"
                style="width: 50%"
                size="small"
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="getOccupation(false)"
                >查询</el-button
              >
            </div>

            <div class="checkbox flex">
              <div
                v-for="(item, index) in cities"
                :key="index"
                style="min-width: 40%; margin: 10px"
              >
                <el-checkbox
                  v-model="item.checked"
                  @change="occupatioChange(item)"
                  >{{ item.occupationName }}</el-checkbox
                >
              </div>
            </div>

            <el-pagination
              style="width: 20vw"
              class="paging"
              small
              layout="prev, pager, next"
              :page-size="occupatioSize"
              :total="occupatioTotal"
              @current-change="getOccupation"
            >
            </el-pagination>
          </div>
        </div>

        <el-button type="text" @click="onAddProfession">添加></el-button>

        <div>
          <div class="title">已选工种</div>
          <div class="container flex" style="padding: 20px">
            <div
              v-for="(item, index) in selectCities"
              :key="index"
              class="item"
            >
              {{ item.name }}

              <el-button type="text" @click="deleteOccupation(index, 2)"
                ><span>删除</span></el-button
              >
            </div>
          </div>
        </div>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
       <el-button class="bgc-bv" @click="onShow()">取消</el-button>
        <el-button class="bgc-bv" @click="onConfirm">确定</el-button>
       
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    stu: {
      type: [String],
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    var validateCompPhone = (rule, value = "", callback) => {
      if (this.selectCities.length == 0) {
        callback(new Error("请选择工种"));
      } else {
        callback();
      }
    };
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    return {
      occupatioSize: 16, // 工种列表每页条数
      occupatioTotal: 0, // 工种列表总条数
      occupationName: "", // 工种名称
      // 选择机构
      CompanyList: [],
      // 多选选择框
      checkList: [],
      cities: [], // 工种列表
      selectCities: [],
      arrOne: [],
      arrTow: [],
      // 表单数据
      Form: {
        compNo: "", // 社会信用代码
        compName: "", // 机构名称
        areaId: "", // 地区
        compAddress: "", // 地址
        compUser: "", // 负责人
        compPhone: "", // 联系电话
        otherContacts: "", // 其他联系人
        otherContactsPhone: "", // 其他联系人电话
        maturityDate: "", // 到期日期
        occupation: "", // 可鉴定工种
        effectiveDate: "", // 成立日期
        remark: "", // 备注
      },

      areaId: "",
      areatreeList: [], // 行政区划
      // 弹窗设置
      dialogShow: false,
      dialogTitle: "可鉴定工种",
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 验证
      rules: {
        compNo: [{ required: true, trigger: "blur", validator: creditCode }],
        compName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        compAddress: [
          { required: true, message: "请输入联系地址", trigger: "change" },
        ],
        compUser: [
          { required: true, message: "请输入负责人", trigger: "change" },
        ],
        compPhone: [
          {
            required: true,
            message: "请输入正确的联系电话",
            trigger: "change",
          },
        ],
        occupation: {
          required: true,
          message: "请选择工种",
          trigger: "change",
          validator: validateCompPhone,
        },
      },
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getareatree();
    if (this.stu == "edit") {
      this.getInfo();
    }
  },
  methods: {
    // 保存更改
    onConfirm() {
      this.arrOne = [];
      this.selectCities.forEach((el) => {
        this.arrOne.push(Object.assign({}, el));
      });
      this.dialogShow = false;
    },
    // 点击勾选工种
    occupatioChange(e) {
      let index = this.checkList.indexOf(e.occupationId);
      if (index == "-1") {
        this.checkList.push(e.occupationId);
        this.arrTow.push({
          id: e.occupationId,
          name: e.occupationName,
        });
      } else {
        this.checkList.splice(index, 1);
        this.arrTow.splice(index, 1);
      }
    },
    // 获取回显数据
    getInfo() {
      console.log(this.id);
      let compId = this.id;
      this.$post("/run/evaluate/company/getInfo", { compId })
        .then((ret) => {
          this.Form =  {
            ...ret.data,
            maturityDate:ret.data.maturityDate ? ret.data.maturityDate.substr(0,10).replaceAll('/','-') : '',
            effectiveDate:ret.data.effectiveDate ? ret.data.effectiveDate.substr(0,10).replaceAll('/','-') :''
            }
          this.getCompanyList(ret.data.compName);
          this.checkList = ret.data.occupation.split(",");
          this.selectCities = ret.data.occupationList;
          ret.data.occupationList.forEach((el) => {
            this.arrTow.push(Object.assign({}, el));
            this.arrOne.push(Object.assign({}, el));
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 表单验证
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    // 提交数据
    doeAjax() {
      const parmar = {
        compName: this.Form.compName, // 机构名称
        compNo: this.Form.compNo, // 社会信用代码
        areaId: this.Form.areaId, // 地区
        compAddress: this.Form.compAddress, // 地址
        compUser: this.Form.compUser, // 负责人
        compPhone: this.Form.compPhone, // 联系电话
        otherContacts: this.Form.otherContacts, // 其他联系人
        otherContactsPhone: this.Form.otherContactsPhone, // 其他联系人电话
        maturityDate: this.Form.maturityDate, // 到期日期
        effectiveDate: this.Form.effectiveDate, // 成立日期
        remark: this.Form.remark, // 备注
      };

      if (this.id) {
        parmar.evaluateCompanyId = this.id;
      }

      if (this.Form.compId) {
        parmar.compId = this.Form.compId;
      }

      // 鉴定工种
      let str = "";
      
      this.arrOne.forEach((element) => {
        if (str) {
          str = str + "," + element.id;
        } else {
          str = element.id;
        }
      });
      parmar.occupation = str;
      console.log(parmar);
      this.$post(
        this.stu == "add"
          ? "/run/evaluate/company/insert"
          : "/run/evaluate/company/modify",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 删除工种
    deleteOccupation(index, type) {
      if (type == 1) {
        this.arrOne.splice(index, 1);
        this.checkList.splice(index, 1);
        return;
      }
      this.selectCities.splice(index, 1);
      this.arrTow.splice(index, 1);
      this.checkList.splice(index, 1);
    },
    // 选择工种
    onAddProfession() {
      console.log("this.checkList", this.checkList);
      console.log("this.selectCities", this.selectCities);
      console.log("cities", this.cities);
      this.selectCities = [];
      this.arrTow.forEach((el) => {
        this.selectCities.push(Object.assign({}, el));
      });
      //  = this.checkList;
    },
    // 显示添加工种弹窗
    onShow() {
      this.dialogShow = !this.dialogShow;
      if (this.dialogShow) {
        this.getOccupation();
      }
    },
    // 获取工种列表
    getOccupation(val) {
      console.log(val);
      let pageNum = 1;
      if (val) {
        pageNum = val;
      }
      console.log("pageNum", pageNum);
      this.$post("/biz/evaluate/company/occupation/page", {
        occupationName: this.occupationName,
        pageNum: pageNum,
        pageSize: this.occupatioSize,
      })
        .then((res) => {
          

          res.data.list.forEach((element) => {
            element.checked = false;
            if (this.checkList.includes(element.occupationId)) {
              element.checked = true;
            }
          });
          this.cities = res.data.list;
          console.log("aa", this.checkList);
          this.occupatioTotal = res.data.total;
        })
        .catch(() => {
          return;
        });
    },

    // 关闭新增鉴定机构
    doClose() {
      this.$emit("close");
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
          console.log(this.areatreeList);
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>

<style lang="less" scoped>
i:hover {
  cursor: pointer;
}
.title {
  text-align: center;
  margin-bottom: 15px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-items: self-start;
}
.container {
  width: 20vw;
  height: 500px;
  background: rgb(219, 219, 219);
  padding: 10px;
  .item {
    min-width: 7vw;
    height: 25px;
    margin-right: 20px;
  }
  .checkbox {
    height: 390px;
    overflow: auto;
  }
  .paging {
    text-align: center;
    margin-top: 10px;
  }
}
</style>